import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {Link} from 'react-router-dom'
import { Grid } from '@mui/material';


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: '0 auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard(props) {

  //const variable to pass to dishPage- will contain the spercific destails of the dish/wine to display
const wine= props.wine;
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Grid item xs={12} sm={6} lg={5}>
    <Card sx={{ margin: '0 auto',marginBottom:'10px ', padding: '5px 0px', fontFamily: 'Urbanist', minHeight:350 }}>
      <CardHeader
        // avatar={
        //   <Avatar aria-label="recipe">
        //     <img src={props.image} style={{ width: 50, height: 50 }} />
        //   </Avatar>
        // }
        title={props.title}
        subheader={props.wine.WineryNeame_Eng}
        titleTypographyProps={{ fontFamily: 'Urbanist', fontWeight: 'bold' }}
      />
      <CardMedia
        component="img"
        image={props.image}
        alt={`${props.title} image missing`}
        height="150"
        width="150"
        sx={{ objectFit: 'contain' }}
      />
      {/* <CardContent>
        <Typography variant="body2" color="text.secondary" fontFamily={"Urbanist"}>
        {props.Description}
        </Typography>
      </CardContent> */}
      
      <CardActions>
        {/* <Button size="small">Share</Button> */}
       {/* link to dish/wine page*/}
       {/* <Link to={`/dishPage/${wine}/${data.age}`}>Go to My Page</Link> */}
       <Link to={`/dishPage/${wine.Id}`} style={{marginLeft:'auto',marginRight:'auto'}} >
        <Button size="small" className='goToButton' >Go to Dish</Button>
        </Link>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton> */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>       
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set
            aside for 10 minutes.
          </Typography>
          <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over
            medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring
            occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a
            large plate and set aside, leaving chicken and chorizo in the pan. Add
            pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook,
            stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography>
          <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and
            peppers, and cook without stirring, until most of the liquid is absorbed,
            15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and
            mussels, tucking them down into the rice, and cook again without
            stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don&apos;t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
    </Grid>
  );
}